import { useApolloClient } from '@apollo/client';
import { PageType } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { mapFormStateToFlightSearchControlCriteriaInput } from 'components/domain/flight/Flight.mappers';
import { mapSearchControlStateToPdpSearchCriteriaInput } from 'components/domain/orl/ORL.mappers';
import { ISearchControlState } from 'components/domain/searchControl/SearchControl.types';
import { useAddFlightSrlHistoryMutation } from 'graphql/history/AddFlightSrlHistory.generated';
import { useAddOrlHistoryMutation } from 'graphql/history/AddOrlHistory.generated';
import { useAddSrlHistoryMutation } from 'graphql/history/AddSrlHistory.generated';
import { mapSearchControlToCriteria } from './SearchControl.mappers';

export const useAddHistoryItem = () => {
  const { id } = useObjectId();
  const pageType = usePageType<PageType>();

  const client = useApolloClient();

  const [addSrlHistoryItem] = useAddSrlHistoryMutation();
  const [addOrlHistoryItem] = useAddOrlHistoryMutation();
  const [addFlightSrlHistoryItem] = useAddFlightSrlHistoryMutation();

  return async (formState: ISearchControlState) => {
    if (formState.type === 'HOTEL') {
      if (
        pageType &&
        [
          PageType.Orl,
          PageType.Pdp,
          PageType.PdpDescription,
          PageType.PdpRegion,
          PageType.PdpReviews,
        ].includes(pageType)
      ) {
        await addOrlHistoryItem({
          variables: {
            searchCriteria: {
              searchControl: mapSearchControlStateToPdpSearchCriteriaInput(
                formState
              ),
              page: { pageNumber: 0, resultsPerPage: 5 },
            },
            productId: id,
          },
        });
      } else {
        await addSrlHistoryItem({
          variables: {
            searchCriteria: {
              searchControl: mapSearchControlToCriteria(formState),
              page: { pageNumber: 0, resultsPerPage: 5 },
            },
          },
        });
      }
    } else {
      await addFlightSrlHistoryItem({
        variables: {
          flightSrlSearchCriteria: {
            searchControl: mapFormStateToFlightSearchControlCriteriaInput(
              formState
            ),
            page: { pageNumber: 0, resultsPerPage: 5 },
          },
        },
      });
    }
    client.refetchQueries({
      updateCache: cache => {
        cache.evict({
          fieldName: `history`,
        });
      },
    });
  };
};
