import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type AddFlightSrlHistoryMutationVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
  flightSrlSearchCriteria: Types.FlightSrlSearchCriteriaInput;
}>;

export type AddFlightSrlHistoryMutation = {
  __typename?: 'Mutation';
  addFlightSrlHistory: {
    __typename?: 'FlightHistoryResponse';
    message?: string | null;
    status: Types.Status;
  };
};

export const AddFlightSrlHistoryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFlightSrlHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'flightSrlSearchCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FlightSrlSearchCriteriaInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFlightSrlHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'flightSrlSearchCriteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'flightSrlSearchCriteria' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type AddFlightSrlHistoryMutationFn = Apollo.MutationFunction<
  AddFlightSrlHistoryMutation,
  AddFlightSrlHistoryMutationVariables
>;

/**
 * __useAddFlightSrlHistoryMutation__
 *
 * To run a mutation, you first call `useAddFlightSrlHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFlightSrlHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFlightSrlHistoryMutation, { data, loading, error }] = useAddFlightSrlHistoryMutation({
 *   variables: {
 *      context: // value for 'context'
 *      flightSrlSearchCriteria: // value for 'flightSrlSearchCriteria'
 *   },
 * });
 */
export function useAddFlightSrlHistoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddFlightSrlHistoryMutation,
    AddFlightSrlHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddFlightSrlHistoryMutation,
    AddFlightSrlHistoryMutationVariables
  >(AddFlightSrlHistoryDocument, options);
}
export type AddFlightSrlHistoryMutationHookResult = ReturnType<
  typeof useAddFlightSrlHistoryMutation
>;
export type AddFlightSrlHistoryMutationResult = Apollo.MutationResult<AddFlightSrlHistoryMutation>;
export type AddFlightSrlHistoryMutationOptions = Apollo.BaseMutationOptions<
  AddFlightSrlHistoryMutation,
  AddFlightSrlHistoryMutationVariables
>;
