import {
  mapFormTravelRoomsToSearchCriteriaInputTravellers,
  mapSearchControlTravelRoomsToFormTravelRooms,
} from '@hotelplan/components.common.travel-rooms';
import { FlightSearchControlComponentInput } from '@hotelplan/graphql.types';
import {
  mapFormFlightPartitionsToFlightPartitionsCriteriaInput,
  mapSearchControlFlightPartitionsToFormFlightPartitions,
} from 'components/domain/flightPartitions/FlightPartitions.mappers';
import {
  prepareFlightPartitionsToFormState,
  prepareFlightPartitionsToCriteriaInput,
} from 'components/domain/flightPartitions/FlightPartitions.utils';
import {
  mapFormFlightTypeToFlightTypeSearchCriteriaInput,
  mapSearchControlFlightTypeToFormFlightType,
} from 'components/domain/flightTypes/FlightTypes.mappers';
import { getSearchControlValuesToFormStateMapper } from 'components/domain/searchControl/SearchControl.mappers';
import { IFlightSearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { FlightSearchControlFragment } from 'graphql/flight/FlightSearchControl.generated';

export const mapFlightHomeSearchControlValuesToFormState = getSearchControlValuesToFormStateMapper<
  FlightSearchControlFragment | undefined,
  IFlightSearchControlFormState
>((searchControl: FlightSearchControlFragment | undefined) => {
  if (!searchControl) return null;

  const flightPartitions = prepareFlightPartitionsToFormState(
    searchControl.flightPartitions,
    searchControl.flightType
  );

  return {
    travelType: searchControl.travelType,
    flightType: mapSearchControlFlightTypeToFormFlightType(
      searchControl.flightType
    ),
    travelRooms: mapSearchControlTravelRoomsToFormTravelRooms(
      searchControl.travellers,
      0 // NOTE: Set rooms to 0 to get rid of rooms selection on the Flight Search Control
    ),
    flightPartitions: mapSearchControlFlightPartitionsToFormFlightPartitions(
      flightPartitions
    ),
  };
});

export const mapFormStateToFlightSearchControlCriteriaInput = (
  formState: IFlightSearchControlFormState | undefined | null
): FlightSearchControlComponentInput => {
  const flightPartitions = prepareFlightPartitionsToCriteriaInput(
    formState?.flightPartitions,
    formState?.flightType
  );

  return {
    travelType: formState?.travelType,
    flightType: mapFormFlightTypeToFlightTypeSearchCriteriaInput(
      formState?.flightType
    ),
    travellers: mapFormTravelRoomsToSearchCriteriaInputTravellers(
      formState?.travelRooms
    ),
    flightPartitions: mapFormFlightPartitionsToFlightPartitionsCriteriaInput(
      flightPartitions
    ),
  };
};
