import {
  mapSearchControlTravelPeriodToFormTravelDates,
  mapTravelDatesModelToTravelPeriodCriteria,
} from '@hotelplan/components.common.travel-dates';
import { mapSearchControlTravelDestinationsToFormTravelDestination } from '@hotelplan/components.common.travel-destination';
import {
  mapFormTravelRoomsToSearchCriteriaInputRooms,
  mapFormTravelRoomsToSearchCriteriaInputTravellers,
  mapSearchControlTravelRoomsToFormTravelRooms,
} from '@hotelplan/components.common.travel-rooms';
import {
  Flight,
  FlightClass,
  OrlRoom,
  PdpFilterCriteriaInput,
  PdpSearchControlCriteriaInput,
  TravelDestinationType,
  WishlistOfferRoom,
} from '@hotelplan/graphql.types';
import {
  ORLOfferMetaData,
  ORLResultsEventMetaData,
} from '@hotelplan/libs.tracking';
import type { IRunSearchData } from '@hotelplan/libs.tracking';
import { parseDateStr } from '@hotelplan/libs.utils';
import { AlternativeRoom } from '@hotelplan/platform-graphql-api';
import {
  mapFiltersStateToFilterCriteriaInput,
  mapFiltersStateToTrackableData,
} from 'components/domain/filters/Filters.mappers';
import { IFiltersFormState } from 'components/domain/filters/Filters.types';
import type { IFlightSectionProps } from 'components/domain/flightSection/FlightSection.types';
import {
  getSearchControlValuesToFormStateMapper,
  mapSearchControlFormStateToTrackableData,
} from 'components/domain/searchControl/SearchControl.mappers';
import { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { mapFormTravelDestinationToSearchCriteriaInputTravelDestination } from 'components/domain/travel-destination/TravelDestination.mappers';
import { CheckoutOrlItemFragment } from 'graphql/orl/CheckoutORLItem.generated';
import { GetOrlSingleOffersQuery } from 'graphql/orl/GetORLSingleOffers.generated';
import type { OrlItemFragment } from 'graphql/orl/ORLItem.generated';
import { SearchControlFragment } from 'graphql/searchControl/SearchControl.generated';
import type { WishlistCheckoutOfferFragment } from 'graphql/wishlist/WishlistOfferFragment.generated';
import type { IORLState } from './ORL.types';

export const mapORLSearchControlComponentToORLControlsFormState = getSearchControlValuesToFormStateMapper<
  SearchControlFragment | undefined,
  ISearchControlFormState
>((searchControl: SearchControlFragment | undefined) => {
  if (!searchControl) return null;

  return {
    travelType: searchControl.travelType,
    travelDates: mapSearchControlTravelPeriodToFormTravelDates(
      searchControl.period
    ),
    travelRooms: mapSearchControlTravelRoomsToFormTravelRooms(
      searchControl.travellers,
      searchControl.rooms,
      searchControl.travellersDistribution
    ),
    travelDestination: mapSearchControlTravelDestinationsToFormTravelDestination(
      searchControl.destinations
    ),
  };
});

// @todo this function might be removed due to backend already has filtering
// @see https://jira.hotelplan.com/browse/HPWEBN-1656
export function filterORLSearchStateDestinations(
  formState: ISearchControlFormState | null | undefined
): ISearchControlFormState | null | undefined {
  if (!formState) return formState;

  return {
    ...formState,
    travelDestination: {
      destinations: formState.travelDestination?.destinations?.filter(
        destination => {
          return [
            TravelDestinationType.Airport,
            TravelDestinationType.Theme,
          ].includes(destination.type);
        }
      ),
    },
  };
}

export const mapSearchControlStateToPdpSearchCriteriaInput = (
  formState: ISearchControlFormState | null | undefined
): PdpSearchControlCriteriaInput => {
  return {
    travellersCriteria: mapFormTravelRoomsToSearchCriteriaInputTravellers(
      formState?.travelRooms
    ),
    rooms: mapFormTravelRoomsToSearchCriteriaInputRooms(formState?.travelRooms),
    travelPeriodCriteria: mapTravelDatesModelToTravelPeriodCriteria(
      formState?.travelDates
    ),
    travelType: formState?.travelType,
    travelDestinations: mapFormTravelDestinationToSearchCriteriaInputTravelDestination(
      formState?.travelDestination
    ),
    travellersDistribution: formState?.travelRooms?.travellersDistribution,
  };
};

export const mapOrlFlightToIFlightSectionProps = (
  flight: Flight
): IFlightSectionProps => {
  const detailedInfo = flight?.segments?.[0];
  const stops = flight?.segments?.slice(1) || [];
  const flightClasses = flight?.segments.reduce(
    (classes: FlightClass[], segment) => {
      if (!classes.includes(segment.flightClass)) {
        classes.push(segment.flightClass);
      }

      return classes;
    },
    []
  );

  return {
    airlineLogo: `https://assets.hotelplan.com/content/airline-logos/${detailedInfo?.airline.iata}_2x.png`,
    departureTime: flight.departureTime,
    departureAirport: flight.departure.iata || '',
    departureAirportPlaceholder: flight.departure.name || '',
    flightDuration: flight.duration,
    stopLocations: stops.map(stop => stop.departure.iata || ''),
    arrivalTime: flight.arrivalTime,
    arrivalAirport: flight.arrival.iata || '',
    arrivalAirportPlaceholder: flight.arrival.name || '',
    overnight: flight?.overnight,
    flightClasses: flightClasses,
    arrivalDate: parseDateStr(flight.arrivalDate),
    departureDate: parseDateStr(flight.departureDate),
  };
};

export const reduceRoomsToRoomTypes = (rooms: OrlRoom[]): string[] => {
  const [firstRoom] = rooms;

  const isRoomTypesAreSame = rooms.every(
    ({ roomType }) =>
      firstRoom.roomType.description === roomType.description &&
      firstRoom.roomType.code === roomType.code
  );

  if (isRoomTypesAreSame && rooms.length > 1) {
    return [
      `${rooms.length} x ${firstRoom.roomType.description.toLowerCase()}`,
    ];
  }
  return rooms.map(room => room.roomType?.description.toLowerCase() || '');
};

export const reduceRoomsToBoardTypes = (rooms: OrlRoom[]): string[] => {
  const [firstRoom] = rooms;

  const isBoardTypesAreSame = rooms.every(
    ({ boardType }) => firstRoom.boardType.code === boardType.code
  );

  if (isBoardTypesAreSame && rooms.length > 1) {
    return [firstRoom.boardType?.description || ''];
  }
  return rooms.map(room => room.boardType?.description || '');
};

export const reduceAlternativeRoomAvailability = (
  rooms: OrlRoom[]
): AlternativeRoom | null => {
  const alternativeAvailabilityTypes = rooms
    .map(room => room.alternativeRoomAvailability)
    .filter(availability => availability);

  const hasAlternativeType = (type: AlternativeRoom) =>
    alternativeAvailabilityTypes.includes(type);

  if (
    hasAlternativeType(AlternativeRoom.RoomAlternativeRoomAndBoard) ||
    (hasAlternativeType(AlternativeRoom.RoomAlternativeBoard) &&
      hasAlternativeType(AlternativeRoom.RoomAlternativeRoom))
  ) {
    return AlternativeRoom.RoomAlternativeRoomAndBoard;
  }

  if (hasAlternativeType(AlternativeRoom.RoomAlternativeBoard)) {
    return AlternativeRoom.RoomAlternativeBoard;
  }

  if (hasAlternativeType(AlternativeRoom.RoomAlternativeRoom)) {
    return AlternativeRoom.RoomAlternativeRoom;
  }

  return null;
};

const filtersFormStateKeyToPdpFilterCriteriaInputKey: {
  [K in keyof IFiltersFormState]: keyof PdpFilterCriteriaInput;
} = {
  boardTypes: 'boardTypes',
  departureAirports: 'departureAirports',
  flightStopOver: 'flightStopOver',
  maxPricePerPerson: 'maxPricePerPerson',
  maxFlightDuration: 'flightDuration',
  roomTypes: 'roomTypes',
  departureWeekdays: 'departureWeekday',
  arrivalAirports: 'arrivalAirports',
  minPrice: 'minPricePerPerson',
  productCode: 'productCode',
  provider: 'provider',
  flightProvider: 'flightProvider',
  arrivalWeekdays: 'returnWeekday',
  directFlightDepartureTime: 'departureTime',
  directFlightArrivalTime: 'departureFlightArrivalTime',
  flightAirlines: 'airlines',
  returnFlightArrivalTime: 'returnFlightArrivalTime',
  returnFlightDepartureTime: 'returnTime',
};

export const mapORLFilterValuesToPdpFilterCriteriaInput = (
  filterState: IFiltersFormState | null | undefined
): PdpFilterCriteriaInput =>
  mapFiltersStateToFilterCriteriaInput(
    filterState,
    filtersFormStateKeyToPdpFilterCriteriaInputKey
  );

export const mapORLStateToTrackableData = (
  state: IORLState
): IRunSearchData => {
  return {
    searchControl: mapSearchControlFormStateToTrackableData(
      state.searchControl
    ),
    filters: mapFiltersStateToTrackableData(state.filters),
  };
};

export const mapSingleOrlItemToTrackableOfferMetaData = (
  offer: CheckoutOrlItemFragment,
  productId?: string
): ORLOfferMetaData => {
  return {
    productId,
    id: offer.id,
    price: offer.pricePerPerson.amount,
    duration: offer.duration,
    transferIncluded: offer.transferIncluded,
    departureDate: offer.departureDate,
    returnDate: offer.returnDate,
    rooms: offer.rooms.length,
    adults: offer.rooms.reduce((acc, room) => {
      return acc + room.adults;
    }, 0),
    children: offer.rooms.reduce((acc, room) => {
      return acc + room.children;
    }, 0),
  };
};

export const mapORLSingleResultsToTrackableResultsEventMetaData = (
  data: GetOrlSingleOffersQuery,
  queryTime = 0
): ORLResultsEventMetaData => {
  const type = 'offers';
  const success = true;
  const perfomance = { time: queryTime };
  const results = data.orl.searchResult.single.page.resultsTotal || 0;

  return {
    type,
    success,
    perfomance,
    results,
    page: data.orl.searchResult.single.page.pageNumber,
    products: data.orl.searchResult.single.offers.map(offer =>
      mapSingleOrlItemToTrackableOfferMetaData(offer)
    ),
  };
};

export function mapWishlistRoomToOrlRoom(room: WishlistOfferRoom): OrlRoom {
  return {
    adults: room.adults,
    children: room.children,
    roomType: {
      code: room.roomCode,
      description: room.roomDescription,
    },
    boardType: {
      code: room.boardCode,
      description: room.boardDescription,
    },
  };
}

export function mapWishlistOfferToOrlOffer(
  offer: WishlistCheckoutOfferFragment,
  giataId?: number
): Omit<OrlItemFragment, 'link'> {
  return {
    ...offer,
    flightAlternativesAvailable: offer.flightAlternativesAvailable || false,
    waitingScreenImage: offer.waitingScreenImage || '',
    rooms: offer.rooms.map(mapWishlistRoomToOrlRoom),
    giataId,
    inWishlist: offer.wishlistItem?.inWishlist || false,
    __typename: undefined,
    lastMinute: undefined,
  };
}
